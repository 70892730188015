<template>
  <div class="flex flex-wrap overflow-visible layout-topbar">
    <div
      class="flex justify-content-start sm:justify-content-center flex-grow-0">
      <button
        class="p-link layout-menu-button layout-topbar-button"
        @click="onMenuToggle">
        <i class="pi pi-bars"></i>
      </button>
      <div class="card-desktop">
        <img
          width="120px"
          src="https://paraiba.pb.gov.br/marca-do-governo/GovPBT.png"
          class="layout-menu-button flex justify-content-center" />
      </div>
      <div class="card-mobile"></div>
    </div>

    <div
      v-if="matriculasList.length > 1"
      class="flex justify-content-start flex-grow-1">
      <Dropdown
        v-model="store.matriculaSelecionada"
        placeholder="."
        data-key="id"
        class="ml-3 layout-topbar-consignataria-dropdown"
        :options="matriculasList">
        <template #value="slotProps">
          <div v-if="slotProps.value">
            <span>
              {{ slotProps.value.matricula }} -
              {{ slotProps.value.orgaoNome }}
            </span>
          </div>
        </template>
        <template #option="slotProps">
          <span class="card-desktop">
            {{ slotProps.option.matricula }} -
            {{ slotProps.option.orgaoNome }}
          </span>
          <span class="card-mobile">
            {{ slotProps.option.orgaoNome }}
          </span>
        </template>
      </Dropdown>
    </div>
    <div class="flex align-items-center flex-grow-0">
      <span class="layout-topbar-user-name">
        {{ $auth.user.nomecompleto }}
      </span>
    </div>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      @click="toggleMenu">
      <i class="pi pi-user"></i>
    </button>
    <Menu
      ref="profilemenu"
      class="profile-menu-item-custom"
      :model="items"
      :popup="true" />
  </div>
</template>

<script>
import MatriculaService from '@/service/MatriculaService'
import { matriculaStore } from '@/stores/matricula'
export default {
  setup() {
    const store = matriculaStore()
    return { store }
  },

  data() {
    return {
      items: [
        {
          label: this.$auth.user.nomecompleto,
          disabled: true,
          class: 'menu-item-responsive',
        },
        {
          separator: true,
          class: 'menu-item-responsive',
        },
        {
          label: 'Sair',
          icon: 'pi pi-sign-out',
          command: () => {
            this.$router.push('/logout')
          },
        },
      ],
      matriculasList: [],
      matriculaValue: {},
    }
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
  },

  mounted() {
    this.carregarMatriculas()
  },

  methods: {
    toggleMenu(event) {
      this.$refs.profilemenu.toggle(event)
    },
    onMenuToggle(event) {
      this.$emit('menu-toggle', event)
    },

    carregarMatriculas() {
      this.matriculaService
        .getMatriculasUsuarioLogado()
        .then((res) => {
          this.matriculasList = res
          this.matriculaValue = res[0]
          this.store.matriculas = res
          this.store.matriculaSelecionada = res[0]
          localStorage.setItem('matriculas', JSON.stringify(res))
          localStorage.setItem('matriculaSelecionada', JSON.stringify(res[0]))
        })
        .catch((err) => {
          this.showToastError(err)
        })
    },

    showToastError(err) {
      const mensagem = err.response
        ? err.response.data.message
        : 'Erro de conexão com a aplicação.'
      this.$toast.add({
        severity: 'error',
        summary: mensagem,
        life: 10000,
      })
    },
  },
}
</script>
<style>
.profile-menu-item-custom span {
  padding-left: 5px;
  padding-right: 5px;
}

@media print {
  .layout-topbar {
    display: none;
  }
}
@media print {
  .layout-topbar {
    display: none;
  }
}
</style>
