<template>
  <div class="layout-menu-container">
    <AppSubmenu
      id="menu-servidor"
      :items="model"
      class="layout-menu"
      :root="true"
      @menuitem-click="onMenuItemClick"
      @keydown="onKeyDown" />
  </div>
</template>

<script>
import AppSubmenu from './AppSubmenu'

export default {
  components: {
    AppSubmenu: AppSubmenu,
  },

  props: {
    model: {
      type: Array,
      required: true,
    },
  },

  methods: {
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event)
    },
    onKeyDown(event) {
      const nodeElement = event.target
      if (event.code === 'Enter' || event.code === 'Space') {
        nodeElement.click()
        event.preventDefault()
      }
    },
  },
}
</script>
