<!-- eslint-disable prettier/prettier -->
<template>
  <div class="text-justify" style="background-color: ghostwhite">
    <h2 style="text-align: center">
      TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS DOS USUÁRIOS DA
      PLATAFORMA PBCONSIG DA CODATA – COMPANHIA DE PROCESSAMENTO DE DADOS DA
      PARAÍBA
    </h2>
    <strong>1. O que é a LGPD?</strong><br /><br />
    <p>
      A Lei Geral de Proteção de Dados (13.709/2018) tem como principal objetivo
      proteger os direitos fundamentais de liberdade, de privacidade e livre
      desenvolvimento da personalidade da pessoa natural.A LGPD estabelece
      normas para a coleta e o tratamento de dados pessoais, a fim de
      impedir vazamento de dados e assegurar a privacidade e a proteção deles.
    </p>
    <strong
      >2. Base Legal e Finalidade do tratamento de dados pelo PBconsig:</strong
    ><br /><br />
    <p>
      O Artigo 7º da Lei Geral de Proteção de Dados (LGPD) estabelece as
      hipóteses de bases legais para o tratamento de dados pessoais por uma
      empresa ou instituição, com o objetivo de proteger os direitos
      fundamentais de liberdade e privacidade do seu titular.
    </p>
    <p>
      O tratamento de dados pessoais somente poderá ser realizado nas seguintes
      hipóteses:
    </p>
    <ol type="I">
      <li>Mediante o fornecimento de consentimento pelo titular;</li>
      <li>
        Para o cumprimento de obrigação legal ou regulatória pelo controlador;
      </li>
      <li>
        <strong
          >Pela administração pública, para o tratamento e uso compartilhado de
          dados necessários à execução de políticas públicas previstas em leis e
          regulamentos ou respaldadas em contratos, convênios ou instrumentos
          congêneres, observadas as disposições do Capítulo IV desta
          Lei;</strong
        >
      </li>
    </ol>
    <p>
      <strong
        >Com base nessa hipótese de Execução de políticas públicas. (Art. 7º,
        III, LGPD ou Art. 11, II, "b", LGPD), a CODATA pode tratar os dados dos
        titulares para atender rotinas de tratamento de dados com a finalidade
        de promover análise de crédito e gestão dos consignados contratados
        pelos servidores públicos junto às consignatárias devidamente
        credenciadas e autorizadas pela Secretaria de Estado da Administração
        (SEAD) e CODATA conforme decreto estadual de n° 41.993/2021.</strong
      >
    </p>
    <strong>3. Consentimento</strong><br /><br />
    <p>
      Para que o titular dos dados possa dar o seu consentimento para o
      tratamento de suas informações pessoais, ele deve ser informado de maneira
      clara, objetiva e transparente sobre a finalidade da coleta das
      informações, por meio de um termo de consentimento.
    </p>
    <p>
      Este documento visa registrar de acordo com o Art. 5º, Inciso XII, da
      LGPD, que o consentimento é a “manifestação livre, informada e inequívoca
      pela qual o titular concorda com o tratamento de seus dados pessoais para
      uma finalidade determinada”.
    </p>
    <p>
      <strong
        >Ao manifestar sua aceitação para com o presente termo, o Titular
        consente e concorda que a CODATA, CNPJ nº 09.189.499/0001-00, com sede
        no Centro Administrativo Estadual, Rua João da Mata, S/N, Jaguaribe -
        CEP: 58.015-020 - Fone: (83) 3208-4490 / (83) 98658-8328 – João Pessoa –
        Paraíba, e-mail do setor jurídico pbconsig@codata.pb.gov.br, doravante
        denominada Controladora, realize o tratamento dos dados pessoais
        especificados na tabela 1 - Tratamento de Dados Pessoais - com a
        finalidade de cadastrar o titular como usuário externo em sistema
        informatizado da CODATA para acesso e assinatura eletrônica em
        autorização de acesso aos dados e margens para empréstimos, novas
        averbações, reserva de cartão de crédito e adiantamento, renegociação e
        compra de dividas (portabilidade) pelos consignatários indicados, pelo
        período de vigência da temporalidade do processo administrativo.
      </strong>
    </p>
    <strong>4. Dados coletados</strong><br /><br />
    <p>
      Nome completo do titular, Documento de Identidade RG, CPF, Matrícula,
      Vínculo, Órgão, Data de admissão, Data de afastamento, Munícpio lotação,
      Salário, Situação (exonerado, afastado, ativo, falecido), E-mail, Celular,
      Data de nascimento, Sexo, Estado Civil, N° do contrato, Valor Prestação.
    </p>
    <strong>5. Direitos dos Titulares</strong><br /><br />
    <p>
      O titular terá direito de informação, acesso, correção, anonimização,
      bloqueio ou eliminação, portabilidade e revogação do seu consentimento. O
      titular poderá exercer seus direitos enviando um pedido por e-mail para:
      gpd@codata365.pb.gov.br
    </p>
    <strong>6. Compartilhamento de Dados</strong><br /><br />
    <p>
      Os dados serão compartilhados com as Consignatárias devidamente
      credenciadas e autorizadas pela Secretaria de Estado da Administração
      (SEAD) e CODATA conforme decreto estadual de n° 41.993/2021.
    </p>
    <strong>7. Revogação</strong><br /><br />
    <p>
      Estou ciente de que, a qualquer momento, posso retirar o consentimento ora
      fornecido, hipótese em que as atividades desenvolvidas pela CODATA, no
      âmbito da nossa relação. Declaro e concordo que os meus Dados Pessoais
      poderão ser armazenados, mesmo após o término do tratamento – inclusive
      após a revogação do consentimento para cumprimento de obrigação legal ou
      regulatória pela CODATA.
    </p>
    <strong>Links Importantes</strong><br />
    <br />Link para a LGPD – Lei Geral de Proteção de Dados<br />
    <a
      href="http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2018/Lei/L13709compilado.htm">
      http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2018/Lei/L13709compilado.htm
    </a>

    <br />Link Tratamento de Dados Pessoais Pelo Poder Público<br />
    <a
      href="https://www.gov.br/anpd/pt-br/documentos-e-publicacoes/guia-poder-publico-anpd-versao-final.pdf">
      https://www.gov.br/anpd/pt-br/documentos-e-publicacoes/guia-poder-publico-anpd-versao-final.pdf<br />
    </a><br>
    <p><ul>
      <li>
        Considerando que fui informado sobre como será realizada a coleta e
        utilização dos Dados, bem como será o tratamento dos Dados pelo PBconsig
        pela CODATA/PB, declaro o meu consentimento em relação ao tratamento dos
        Dados para as finalidades aqui propostas.
      </li>
    </ul></p>
  </div>
</template>
<script>
export default {}
</script>
<style lang=""></style>
