export default class UsuarioService {
  constructor(http) {
    this._http = http
  }

  async usuarioPossuiPermissaoGestao() {
    const { data } = await this._http.get(`/api/usuarios/possuiPermissaoGestao`)
    return data
  }

  async usuarioPossuiPermissaoGeradorContracheque() {
    const { data } = await this._http.get(
      `/api/usuarios/possuiPermissaoGerarContracheque`,
    )
    return data
  }
}
