var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('ul',[_vm._l((_vm.items),function(item,i){return [(_vm.visible(item) && !item.separator)?_c('li',{key:item.label || i,class:[
        {
          'layout-menuitem-category': _vm.root,
          'active-menuitem': _vm.activeIndex === i && !item.to && !item.disabled,
        } ],attrs:{"role":"none"}},[(_vm.root)?[_c('div',{staticClass:"layout-menuitem-root-text",attrs:{"id":'menu-item-servidor' + item.label,"aria-label":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('AppSubmenu',{attrs:{"items":_vm.visible(item) && item.items},on:{"menuitem-click":function($event){return _vm.$emit('menuitem-click', $event)}}})]:[(item.to)?_c('router-link',{directives:[{name:"ripple",rawName:"v-ripple"}],class:[
            item.class,
            'p-ripple',
            { 'p-disabled': item.disabled },
            { 'router-link-exact-active': _vm.subIsActive(item.to.name) } ],style:(item.style),attrs:{"id":'menu-item-servidor-' + item.to.name,"to":item.to,"target":item.target,"aria-label":item.label,"exact":"","role":"menuitem"},on:{"click":function($event){return _vm.onMenuItemClick($event, item, i)}}},[(!item.novidade)?_c('div',[_c('i',{class:item.icon}),_c('span',[_vm._v(_vm._s(item.label))]),(item.items)?_c('i',{staticClass:"pi pi-fw pi-angle-down menuitem-toggle-icon"}):_vm._e()]):_c('div',{staticClass:"text-primary"},[_c('i',{class:item.icon}),_c('span',[_vm._v(_vm._s(item.label))]),(item.items)?_c('i',{staticClass:"pi pi-fw pi-angle-down menuitem-toggle-icon"}):_vm._e(),_c('Chip',{staticClass:"ml-2 bg-primary",attrs:{"label":"Novo"}})],1),(item.badge)?_c('Badge',{attrs:{"value":item.badge}}):_vm._e()],1):_vm._e(),(!item.to)?_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],class:[item.class, 'p-ripple', { 'p-disabled': item.disabled }],style:(item.style),attrs:{"href":item.url || '#',"target":item.target,"aria-label":item.label,"role":"menuitem"},on:{"click":function($event){return _vm.onMenuItemClick($event, item, i)}}},[_c('i',{class:item.icon}),_c('span',[_vm._v(_vm._s(item.label))]),(item.items)?_c('i',{staticClass:"pi pi-fw pi-angle-down menuitem-toggle-icon"}):_vm._e(),(item.badge)?_c('Badge',{attrs:{"value":item.badge}}):_vm._e()],1):_vm._e(),_c('transition',{attrs:{"name":"layout-submenu-wrapper"}},[_c('AppSubmenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndex === i),expression:"activeIndex === i"}],attrs:{"items":_vm.visible(item) && item.items},on:{"menuitem-click":function($event){return _vm.$emit('menuitem-click', $event)}}})],1)]],2):_vm._e(),(_vm.visible(item) && item.separator)?_c('li',{key:'separator' + i,staticClass:"p-menu-separator",attrs:{"role":"separator"}},[_c('hr')]):_vm._e()]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }